
import interact from 'interactjs';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { OrbitSpinner } from 'epic-spinners';

import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

export default {
  name: 'EffectsSelect',
  components: {
    OrbitSpinner,
  },
  mixins: [
    effectsMixin,
    uiMixin,
  ],
  props: {
    effectTargets: {
      type: Array,
      default() {
        return [];
      },
    },
    itemId: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isDragging: false,

      draggingEffectName: undefined,
      draggingEffectX: undefined,
      draggingEffectY: undefined,

      draggingOverName: undefined,

      effectFocusedName: undefined,
    };
  },
  computed: {
    item() {
      // this always assumes the item is an action
      return this.itemId ? this.$store.getters.getActionById(this.itemId) : undefined;
    },
    effectsLocal() {
      const effects = this.$store.getters['effects/getItemsForTargets'](this.effectTargets);

      const effectsCustom = this.$store.getters['effects/itemsCustom']
        .filter(ef => this.isEffectCustomApplicable(ef, this.item))
        .map(ef => ({
          ...ef,
          name: ef.label,
          description: ef.explanation,
        }));

      return effects.concat(effectsCustom)
        .map(ef => ({
          ...ef,
          formula: [], // Effect.Formula[ef.name] || [],
          isDragging: this.draggingEffectName === ef.name,
          isApplicable: true, // this.getIsEffectApplicable(ef),
          isHighlighted: this.getIsEffectHighlighted(ef.name),
        }));
    },
    effectsWithStyle() {
      const points = this.pointsOnCircle;
      return this.effectsLocal.map((ef, i) => {
        const isDragging = this.isDragging && this.draggingEffectName === ef.name &&
          typeof this.draggingEffectX === 'number' && typeof this.draggingEffectY === 'number';

        const point = points[i];
        const left = isDragging ? this.draggingEffectX : Math.round(point[0]);
        const top = isDragging ? this.draggingEffectY : Math.round(point[1]);

        return {
          ...ef,
          style: {
            left: `${left}px`,
            top: `${top}px`,
          },
        };
      });
    },
    effectsFromFormula() {
      return this.effectsLocal.filter(ef => ef.formula.length > 0 && ef.isApplicable);
    },
    pointsOnCircle() {
      return this.getPointsOnCircle(this.effectsLocal.length);
    },
    draggingOverEffectProps() {
      return this.effectsLocal.find(ef => ef.name === this.draggingEffectName);
    },
    draggingOverFormula() {
      let formula = {
        name: undefined,
        props: undefined,
        formula: [],
      };

      if (this.isDragging) {
        const result = this.getApplicableFormulaForEffects([this.draggingEffectName, this.draggingOverName]);

        if (result) {
          formula = result;
        }
      }

      return formula;
    },
    isDraggingOverFormula() {
      return Boolean(this.draggingOverFormula.name);
    },
    isDraggingOverMiddle() {
      return this.draggingOverName === 'middle';
    },
    isDraggingEffectApplicable() {
      return this.draggingOverEffectProps ? this.draggingOverEffectProps.isApplicable : false;
    },
    isDraggingOverInvalid() {
      return this.draggingOverName && !this.isDraggingOverFormula &&
        (!this.isDraggingOverMiddle || !this.isDraggingEffectApplicable);
    },
    effectToApply() {
      let ef;

      if (!this.isDraggingOverInvalid) {
        if (this.isDraggingOverFormula) {
          ef = this.effectsLocal.find(ef => ef.name === this.draggingOverFormula.name);
        } else if (this.isDraggingEffectApplicable && this.isDraggingOverMiddle) {
          ef = this.draggingOverEffectProps;
        }
      }

      if (!ef && this.effectFocusedName) {
        ef = this.effectsLocal.find(ef => ef.name === this.effectFocusedName);
      }

      return ef;
    },
    effectToApplyName() {
      return (this.effectToApply && !this.effectToApply.isCustom)
        ? this.effectToApply.name
        : undefined;
    },
  },
  mounted() {
    Haptics.impact({ style: ImpactStyle.Medium });

    interact('#effects-select-circle-effects-all .effects-select-circle-effect').draggable({
      modifiers: [
        interact.modifiers.restrict({
          restriction: 'parent',
        }),
      ],
      listeners: {
        start: ($event) => {
          this.handleDragStart($event);
        },
        move: ($event) => {
          this.handleDragMove($event);
        },
        end: ($event) => {
          this.handleDragEnd($event);
        },
      },
    });

    const dropzones = [
      '#effects-select-circle-effects-all .effects-select-circle-effect',
      '#effects-select-circle-effects-all #effects-select-circle-middle-dropzone',
    ];

    interact(dropzones.join(',')).dropzone({});
  },
  methods: {
    getPointsOnCircle(n) {
      const coordinates = [];
      const radians = 2 * Math.PI; // == 360 degrees
      const startAngleOffset = -0.75 * (radians / 2);

      for (let i = 0; i < n; i++) {
        const radius = 145;
        const angle = (i * (radians / n)) + startAngleOffset;
        const x = (radius * Math.cos(angle)) + radius;
        const y = (radius * Math.sin(angle)) + radius;
        coordinates.push([x, y]);
      }

      return coordinates;
    },
    getIsEffectInAnyApplicableFormula(/* name */) {
      return false;

      /*
      const formulas = Object.keys(Effect.Formula).map(fName => ({
        name: fName,
        ...Effect.Setting[fName],
      }));

      return Boolean(formulas.find(f => Effect.Formula[f.name].includes(name) && this.getIsIntersectingWithTargets(f.targets)));
      */
    },
    getApplicableFormulaForEffects(/* efs */) {
      /*
      let formula;

      const name = Object.keys(Effect.Formula).find((name) => {
        return _.intersection(Effect.Formula[name], efs).length === efs.length &&
          this.getIsIntersectingWithTargets(Effect.Setting[name].targets);
      });

      if (name && Effect.Formula[name] && Effect.Setting[name]) {
        formula = {
          name,
          props: Effect.Setting[name],
          formula: Effect.Formula[name],
        };
      }

      return formula;
      */
    },
    getIsEffectHighlighted(name) {
      return this.draggingEffectName === name || Boolean(this.getApplicableFormulaForEffects([name, this.draggingEffectName]));
    },
    handleDragStart($event) {
      Haptics.selectionStart();
      this.isDragging = true;
      this.effectFocusedName = undefined;
      this.draggingEffectName = $event.target.getAttribute('data-name');
      this.draggingEffectX = parseInt($event.target.style.left, 10);
      this.draggingEffectY = parseInt($event.target.style.top, 10);
    },
    handleDragMove($event) {
      if (this.draggingEffectName === $event.target.getAttribute('data-name')) {
        if (typeof this.draggingEffectX !== 'number') {
          this.draggingEffectX = 0;
        }

        if (typeof this.draggingEffectY !== 'number') {
          this.draggingEffectY = 0;
        }

        this.draggingEffectX = this.draggingEffectX + $event.dx;
        this.draggingEffectY = this.draggingEffectY + $event.dy;

        if ($event.dragEnter) {
          Haptics.selectionChanged();
          this.draggingOverName = $event.dragEnter.getAttribute('data-name');
        } else if ($event.dragLeave) {
          Haptics.selectionChanged();
          this.draggingOverName = undefined;
        }
      }
    },
    handleDragEnd() {
      Haptics.selectionEnd();
      this.effectFocusedName = undefined;

      if (this.effectToApply) {
        this.onSelect(this.effectToApply);
      }

      this.isDragging = false;

      this.draggingEffectName = undefined;
      this.draggingEffectX = undefined;
      this.draggingEffectY = undefined;
      this.draggingOverName = undefined;
    },
  },
};
