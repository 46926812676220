
import { Sprite } from '~/api/src/models/Sprite';

export default {
  name: 'SpriteView',
  props: {
    name: {
      type: String,
      default: Sprite.Name.enum.Swirl01,
    },
    direction: {
      type: String,
      default: 'normal',
    },
    isAnimating: {
      type: Boolean,
      default: true,
    },
    iterationCount: {
      type: String,
      default: 'infinite',
    },
  },
  computed: {
    setting() {
      const defaultSetting = {
        imgSrc: undefined,
        xCount: 0,
        yCount: 0,
        animSeconds: 0,
      };

      return this.name ? Sprite.Setting[this.name] : defaultSetting;
    },
    style() {
      const imgWidthPercent = this.setting.xCount * 100;
      const imgHeightPercent = this.setting.yCount * 100;

      return {
        backgroundImage: `url(${this.setting.imgSrc})`,
        '--img-src': this.setting.imgSrc,
        '--x-step-count': this.setting.xCount,
        '--y-step-count': this.setting.yCount,
        /* Since we move from left-to-right and then top-to-bottom,
         * getting through all of Y means being finished, so that is our
         * total animation time.
         * The animation time for X is 1/Y, since all of X is one row of Y.
         */
        '--x-anim-time': `${(1 / this.setting.yCount) * this.setting.animSeconds}s`,
        '--y-anim-time': `${this.setting.animSeconds}s`,
        '--anim-direction': this.direction,
        '--anim-iteration-count': this.iterationCount,
        '--img-width': `${imgWidthPercent}%`,
        '--img-height': `${imgHeightPercent}%`,
        '--x-end': `-${imgWidthPercent}%`,
        '--y-end': `-${imgHeightPercent}%`,
      }
    },
  },
};
