
import { Sprite, SpriteCanvasHelper } from 'mixel';
import { hashCode } from '../lib/util';
import effectsMixin from '../mixins/effects';
import SpriteView from '~/components/sprite-view';

export default {
  name: 'EffectTile',
  components: {
    SpriteView,
  },
  mixins: [
    effectsMixin,
  ],
  props: {
    effectName: {
      type: String,
      default: '',
    },
    customHash: {
      type: String,
      default: undefined,
    },
    inheritSize: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    invisibleName: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    isHypothetical: {
      type: Boolean,
      default: false,
    },
    effectTargets: {
      type: Array,
      default() {
        return [];
      },
    },
    tabindex: {
      type: String,
      default: '0',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      effectCustom: undefined,
    };
  },
  computed: {
    isCustom() {
      return !this.effectName && this.customHash;
    },
    effect() {
      return !this.isCustom ? this.effects.find(ef => ef.name === this.effectName) : undefined;
    },
    effectClasses() {
      return {
        'effect-tile-hide-name': this.hideName,
        'ec-pb-1': !this.hideName,
        'effect-tile-inherit-size': this.inheritSize,
        'effect-tile-unknown': !this.effect && !this.effectCustom,
        'effect-tile-highlighted': this.highlight,
        'effect-tile-hypothetical': this.isHypothetical,
      };
    },
    effectIconStyles() {
      const styles = {
        color: 'var(--sl-color-neutral-600)',
      };

      if (this.effect && this.effect.color) {
        styles.color = this.effect.color;
      }

      return styles;
    },
    effectLabel() {
      let label;

      if (this.isCustom && this.effectCustom) {
        label = this.effectCustom.label;
      } else if (this.effect) {
        label = this.effect.verb;
      }

      return label;
    },
  },
  watch: {
    customHash() {
      this.setCustom();
    },
  },
  mounted() {
    this.setCustom();
  },
  methods: {
    removeCanvas() {
      const $el = this.$refs.effectTileIcon;

      if ($el) {
        $el.children.forEach(($c) => {
          if ($c.tagName === 'CANVAS') {
            $el.removeChild($c);
          }
        });
      }
    },
    setCustom() {
      let e;

      if (this.isCustom) {
        e = this.$store.getters['effects/getCustomFromHash'](this.customHash);

        this.effectCustom = e;

        this.$nextTick(() => {
          this.removeCanvas();
          const $el = this.$refs.effectTileIcon;

          if ($el) {
            const seed = hashCode(this.effectCustom.hash);
            const mask = this.effectCustom.how.mask;

            const sprite = new Sprite(mask, {
              colored: true,
              saturation: this.effectCustom.spriteOptions?.saturation || 0.5,
              colorVariations: this.effectCustom.spriteOptions?.colorVariations || 0.2,
              seed,
            });

            const canvas = SpriteCanvasHelper.createCanvas(sprite);
            $el.appendChild(canvas);
          }
        });
      } else {
        this.effectCustom = undefined;
        this.removeCanvas();
      }
    },
    handleFocus() {
      this.$emit('focus', this.effectName);
    },
    handleBlur() {
      this.$emit('blur', this.effectName);
    },
    handleSelect() {
      this.$emit('select', this.effectName);
    },
  },
};
